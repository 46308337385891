import * as React from 'react'
import { useServiceMetaData } from '../../components/hooks/schema/servicesSchema'
import { StaticImage } from 'gatsby-plugin-image'

import Layout from '../../components/layout'
import Seo from '../../components/seo'
import SiteWidthWrapper from '../../components/wrappers/siteWidthWrapper'
import CallOut from '../../components/callOut'
import SplashTwo from '../../components/splashTwo'

const SewerBackupPage = () => {
  const data = useServiceMetaData()

  const ServiceSchema = ` 
    {
      "@context": "https://schema.org/",
      "@type": "Service",
      "serviceType": "sewer backup damage repair services",
	  "alternateName": "sewer backup damage restoration services",
      "provider": {
        "@type": "LocalBusiness",
        "name": "${data.title}",
        "image": "${data.siteUrl}${data.siteLogo}",
        "address": "${data.businessAddress}",
        "telephone": "${data.businessNumber}",
        "priceRange": "$$ - $$$$"
      },
      "areaServed": {
            "@type": "GeoCircle",
            "geoMidpoint": {
                "@type": "GeoCoordinates",
                "latitude": ${data.geoLon}
                "longitude": ${data.geoLat}
            },
            "geoRadius": ${data.geoRad}
        },
      "hasOfferCatalog": {
        "@type": "OfferCatalog",
        "name": "sewer backup damage repair services",
        "itemListElement": [
          {
            "@type": "OfferCatalog",
            "name": "sewer backup damage repair services",
            "itemListElement": [
              {
                "@type": "Offer",
                "itemOffered": {
                  "@type": "Service",
                  "name": "sewer backup damage restoration services"
                }
              },
              {
                "@type": "Offer",
                "itemOffered": {
                  "@type": "Service",
                  "name": "sewer backup damage repair services"
                }
              }
            ]
          }
        ]
      }
    }
  `

  return (
    <Layout>
      <Seo
        title="Sewer Backup Repair"
        description="Sewage backup is not a problem to take lightly or attempt to fix alone. We provide 24/7 professional sewage clean-up services."
        canonical="https://ecotechrestoration.ca/services/sewer-back-up-damage-repair-and-restoration/"
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: ServiceSchema }}
      />
      {/* Heros */}
      <div className="heroWrapper">
        <StaticImage
          className="heroImage"
          src="../../images/heros/sewer.jpg"
          quality={95}
          alt="Sewer Backup Repair"
        />
        <div className="overlay" />
        <h1 className="services__heading heroText">
          <div className="overlay" />
          <h1 className="services__heading heroText">
            Sewer Backup &amp; Cleanup Services
          </h1>
        </h1>
      </div>
      <SiteWidthWrapper>
        <CallOut>Do you have a Sewer Backup Cleanup Emergency?</CallOut>
        <p>
          Contact EcoTech Restoration to hire qualified technicians for sewage
          cleanup throughout Vancouver, Tri-Cities or the Maple Ridge / Pitt
          Meadows area. Our crews are available 24/7 for emergency calls and
          will be at your property within in approximately 20 to 60 minutes of
          notification!
        </p>
        <p>
          Our sewage cleanup techs have the training required to clean up
          commercial and residential spills immediately. We will remove any type
          and quantity of sewage inside and outside of your property with our
          advanced, trusted technique.
        </p>
        <p>
          Sewage spills are a nightmare for businesses and homeowners because
          you need to ensure that your property doesn't suffer from the
          long-term effects of sewage backup. We will handle all water damage
          quickly to resolve the situation before it gets worse.
        </p>
        <p>
          We will deal with any sewage cleanup service, including damage from
          burst pipes, overflowing toilets, sewage backup, grease trap backups,
          and sewer line breaks.
        </p>
        <p>
          Sewage water damage can happen any time of the year. In the winter,
          this might be because of a pipe freeze. In spring, it could result
          from heavy rainfalls that cause sewage to back up into your property.
        </p>
        <h2>A few kinds of Sewage Water Damage</h2>
        <p>
          Sometimes, sewage pipes get clogged if someone uses them to dispose of
          grease. The roots from trees or shrubs may also grow into the sewer
          system, spreading out and leading to a total blockage spilling into
          your property!
        </p>
        <p>
          Sewage water backups also often occur after heavy rains. How well the
          sewage system holds up can often depend on your area and when the
          sewage system was created. Some older sewage systems cannot keep up
          with our growing cities, meaning they cannot handle excess water well.
          Hence, the system pushes sewage back into your property, leading to
          toilet water damage.
        </p>
        <p>
          It's easy to see that the damage a sewage spill causes can take many
          different forms. This includes warped floorboards, mould on your
          drywall, behind baseboards, on cabinets, and destruction of your
          possessions. Worst of all, sewage contains bacteria and pathogens that
          are often dangerous to your health.
        </p>
        <p>
          Fortunately, our experienced team also knows how to perform mould
          cleanup, and are licensed, bonded, and insured.
        </p>

        <p>
          We will:
          <ul>
            <li>
              Quickly remove sewage water from Your Home or commercial property
            </li>
            <li>Clean and Disinfect any areas impacted by sewage water</li>
            <li>
              Mitigate sewage water damage by repairing all damaged or Leaking
              Pipes
            </li>
            <li>Assessing for signs of mould growth</li>
            <li>Remove Odors</li>
          </ul>
        </p>

        <h2>Local Sewage Cleanup Services</h2>
        <p>
          The effects of raw sewage are not just unpleasant; they can be
          devastating. You may think that the smell will disappear after you
          wash up, but it doesn't work like this. Instead, what typically
          happens is you're left with more bacteria and viruses spreading and
          growing throughout your property, increasing risks for illness for
          everyone who comes into contact with them!
        </p>
        <p>
          We will use our professional-grade pump-trucks, sewage extractors,
          dehumidifiers, and HEPA air scrubbers to movers to get your sewage
          cleaned up. We'll also be using specialized disinfectants to eliminate
          any odours left behind by a spill for you!
        </p>
        <p>
          No one deserves to have their property destroyed by a sewage spill.
          But, when there's been an overflow, the last thing you need is more
          stress. We provide peace of mind with our sewage water restoration
          services and will clean up any mess to get back on track as quickly as
          possible.
        </p>
        <SplashTwo title="Sewer BackUp Repair" />
      </SiteWidthWrapper>
    </Layout>
  )
}

export default SewerBackupPage
